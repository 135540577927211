<template>
  <section class="cookie_disclosure--container" :class="{'cookie_disclosure--container-active': isActive}">
    <div class="cookie_disclosure--grid">
      <div>
        <p>Our website uses cookies to improve your experience and personalize content.</p>
        <p>Some cookies may collect personal data, which we use in accordance with our privacy policy.</p>
        <p>You can manage your cookie preferences using the buttons here. You can <a :href="privacyPolicyPath">view our privacy policy here.</a></p>
      </div>
      <div class="cookie_disclosure--button_container">
        <a class="cookie_disclosure--button cookie_disclosure--button-all" :class="{'cookie_disclosure--button-disabled': isAllCookiesDisabled}"
           href="#" @click.prevent="setUserSetting('all')">
          ALL COOKIES
        </a>
        <a class="cookie_disclosure--button" :class="{'cookie_disclosure--button-disabled': isRequiredCookiesDisabled}"
           href="#" @click.prevent="setUserSetting('required')">
          REQUIRED COOKIES
        </a>
      </div>
    </div>
  </section>
</template>

<script lang="ts">
/**
 * @description Self-contained cookie disclosure component
 * @author Jasper Howard <jhoward@peppermillcas.com>
 * @version	1.1.0
 * @copyright	Copyright (C) 2023-2024, Peppermill Inc. All rights reserved. Redistribution and use in source and binary forms, with or without modification, are not permitted.
 * @chanagelog
 *  2023-09-18 (1.0.0) (jhoward)
 *    - Initial release for <cookie-disclosure> component.
 *
 *  2023-10-26 (1.0.1) (jhoward)
 *    - Update copy in <cookie-disclosure>.
 *
 *  2024-01-30 (1.1.0) (jhoward)
 *    - Add "refreshOnRequiredOnly" prop to <cookie-disclosure>.
 */
import {defineComponent} from 'vue';

interface CookieDisclosureCachedData {
  DATA: '' | 'required' | 'all';
  TIMESTAMP: number;
}

export default defineComponent({
  name: "CookieDisclosure",
  props: {
    isAllCookiesDisabled: {
      type: Boolean,
      default: false
    },
    isRequiredCookiesDisabled: {
      type: Boolean,
      default: false
    },
    refreshOnRequiredOnly: {
      type: Boolean,
      default: false
    },
    privacyPolicyPath: {
      type: String,
      default: '/about-us/privacy-policy'
    }
  },
  data() {
    return {
      isActive: false,
      storageKey: 'cookie_disclosure',
      storageTTL: 60 * 60 * 24 * 365 * 1000,
      userSetting: null as null | string,
    };
  },
  mounted() {
    window.isCookieAllowed = (type) => {
      if (this.userSetting && this.userSetting !== 'all') {
        return type.toLowerCase() === this.userSetting.toLowerCase();
      }

      return true;
    };

    window.setTimeout(() => {
      this.userSetting = this.getUserSetting();
    }, 132);
  },
  watch: {
    userSetting() {
      this.isActive = !this.userSetting || 0 === this.userSetting.length;
    }
  },
  methods: {
    getUserSetting(): string | null {
      const cachedData = window.pepHelperFunctions.getCachedData(this.storageKey, this.storageTTL, true) as false | CookieDisclosureCachedData;
      if (false !== cachedData) {
        return cachedData.DATA;
      }

      return '';
    },
    setUserSetting(value: string) {
      window.pepHelperFunctions.setCachedData(this.storageKey, value, true);
      this.userSetting = value;

      if (this.userSetting === 'required' && this.refreshOnRequiredOnly) {
        setTimeout(() => {
          window.location.reload();
        }, 132);
      }
    }
  }
})
</script>

<style scoped>
.cookie_disclosure--container {
  position: fixed;
  bottom: 1em;
  left: 0;
  right: 0;
  z-index: 150;
  opacity: 0;
  visibility: hidden;
  will-change: transform, opacity, visibility;
  transform: translateY(1em);
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), opacity 150ms cubic-bezier(0.4, 0, 0.2, 1), visibility 1ms linear 150ms;
}

.cookie_disclosure--container-active {
  opacity: 1;
  visibility: visible;
  transform: none;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), opacity 150ms cubic-bezier(0.4, 0, 0.2, 1), visibility 1ms linear;
}

.cookie_disclosure--grid {
  display: grid;
  grid-template-columns: 1fr max-content;
  grid-gap: 0.5em;
  max-width: 1200px;
  padding: 0.5em 1em;
  margin: 0 auto;
  background: #FFF;
  box-shadow: 0 0 32px rgba(0, 0, 0, 0.54);
  border-radius: 0.25em;
}

.cookie_disclosure--button_container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 0.5em;
  align-self: start;
}

.cookie_disclosure--button {
  display: block;
  outline: none;
  margin-left: 0.5em;
  padding: 0.5em 0.75em;
  text-decoration: none;
  color: rgb(var(--primary-color, 0, 121, 102));
  text-transform: uppercase;
  background-color: #FFF;
  will-change: background-color;
  text-align: center;

  transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1);
}

.cookie_disclosure--button-disabled {
  background-color: #EEE !important;
  color: #808080 !important;
  cursor: default;
}

.cookie_disclosure--button:hover,
.cookie_disclosure--button:focus {
  background-color: #EEE;
}

.cookie_disclosure--button:active {
  background-color: #CCC;
}

.cookie_disclosure--button-all {
  color: #FFF !important;
  background-color: rgba(var(--primary-color, 0, 121, 102), 0.87);
}

.cookie_disclosure--button-all:hover,
.cookie_disclosure--button-all:focus {
  background-color: rgba(var(--primary-color, 0, 121, 102), 1);
}

@media (max-width: 800px) {
  .cookie_disclosure--grid {
    border-radius: initial;
    grid-template-columns: 1fr;
  }
}
</style>