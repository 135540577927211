var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "section",
    {
      staticClass: "cookie_disclosure--container",
      class: { "cookie_disclosure--container-active": _vm.isActive },
    },
    [
      _c("div", { staticClass: "cookie_disclosure--grid" }, [
        _c("div", [
          _c("p", [
            _vm._v(
              "Our website uses cookies to improve your experience and personalize content."
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "Some cookies may collect personal data, which we use in accordance with our privacy policy."
            ),
          ]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "You can manage your cookie preferences using the buttons here. You can "
            ),
            _c("a", { attrs: { href: _vm.privacyPolicyPath } }, [
              _vm._v("view our privacy policy here."),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "cookie_disclosure--button_container" }, [
          _c(
            "a",
            {
              staticClass:
                "cookie_disclosure--button cookie_disclosure--button-all",
              class: {
                "cookie_disclosure--button-disabled": _vm.isAllCookiesDisabled,
              },
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.setUserSetting("all")
                },
              },
            },
            [_vm._v("\n        ALL COOKIES\n      ")]
          ),
          _vm._v(" "),
          _c(
            "a",
            {
              staticClass: "cookie_disclosure--button",
              class: {
                "cookie_disclosure--button-disabled":
                  _vm.isRequiredCookiesDisabled,
              },
              attrs: { href: "#" },
              on: {
                click: function ($event) {
                  $event.preventDefault()
                  return _vm.setUserSetting("required")
                },
              },
            },
            [_vm._v("\n        REQUIRED COOKIES\n      ")]
          ),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }