import Vue from "vue/dist/vue.esm";
import CookieDisclosure from "./library/js/components/CookieDisclosure.vue";

require('./library/js/pepDateFunctions');
require('./library/js/pepHelperFunctions');
require('./library/js/main');

Vue.component('cookie-disclosure', CookieDisclosure);

window.cookieDisclosureVueApp = new Vue({})
  .$mount("#cookie_disclosure_vue_app");